import React, { useState, useEffect } from 'react';
import { TonConnectUIProvider, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';

const transaction = {
    validUntil: Date.now() + 5 * 60 * 1000, // 5 минут
    messages: [
        {
            address: "UQCITGpH9TMXprqiiU-q56eSB_t6Td1a2N77-ACfIG3g9HiY",
            amount: "20000000",
        },
    ],
};

const SendTransaction = () => {
    const [status, setStatus] = useState('');
    const tonConnectUI = useTonConnectUI();

    const handleSendTransaction = async () => {
        if (tonConnectUI) {
            try {
                setStatus('Sending transaction...');

                const result = await tonConnectUI[0].transactionModal(transaction);

                if (result.success) {
                    setStatus(`Transaction sent successfully. Hash: ${result.transactionHash}`);
                } else {
                    setStatus('Transaction failed');
                }
            } catch (error) {
                setStatus('Error sending transaction');
                console.error(error);
            }
        }
    };

    console.log(tonConnectUI[0]);

    return (
        <div>
            <button onClick={handleSendTransaction}>
                Send transaction
            </button>
            <p>{status}</p>
        </div>
    );
};

const App = () => {
    return (
        <TonConnectUIProvider config={{project: 'your-project-id', url: 'https://your-url' }}>
            <SendTransaction />
        </TonConnectUIProvider>
    );
};

export default App;

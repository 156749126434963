import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ListItem, List, ListItemText, Typography, Dialog, DialogTitle, DialogContent, Divider, IconButton, Collapse } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import RefsIcon from './../../Assets/Svg/refs_icon';
import RefsIcon from './../../Assets/Pngs/my_refs.png';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShopCard from "../Cards/ShopCard";

const StyledList = styled(List)(({ theme }) => ({
    backgroundColor: 'var(--tg-theme-secondary-bg-color)',
    borderRadius: '12px',
    padding: '8px 0',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: '12px 24px',
    borderRadius: '12px',
    margin: '4px 0px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    height: "60px",
    cursor: 'default',
    transition: 'background-color 0.3s',
    backgroundColor: "var(--tg-theme-bg-color)",
}));

const SubListItem = styled(ListItem)(({ theme }) => ({
    width: "calc(100% - 12px)",
    padding: '8px 16px',
    borderRadius: '8px',
    margin: '4px 12px',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
}));

const RotatingIconButton = styled(IconButton)(({ open }) => ({
    transition: 'transform 0.5s ease',
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
}));

const MyRefs = ({ firstLevel, secondLevel }) => {
    const { t } = useTranslation();
    const [openRefsModal, setOpenRefsModal] = useState(false);
    const [openItems, setOpenItems] = useState({});

    const secondLevelMap = secondLevel.reduce((acc, ref) => {
        if (!acc[ref.owner]) {
            acc[ref.owner] = [];
        }
        acc[ref.owner].push(ref);
        return acc;
    }, {});

    const handleOpenRefsModal = () => {
        setOpenRefsModal(true);
    };

    const handleCloseRefsModal = () => {
        setOpenRefsModal(false);
    };

    const handleClick = (id) => {
        setOpenItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    React.useEffect(() => {
        const backButton = window.Telegram.WebApp.BackButton;

        if(openRefsModal) {
            backButton.show();
            window.Telegram.WebApp.SecondaryButton.hide();
            window.Telegram.WebApp.MainButton.hide();
            backButton.onClick(() => {
                setOpenRefsModal(false);
            })
        } else {
            window.Telegram.WebApp.SecondaryButton.show();
            window.Telegram.WebApp.MainButton.show();
            backButton.hide();
            backButton.offClick();
        }
    }, [openRefsModal]);

    return (
        <Box>
            <ListItem
                onClick={handleOpenRefsModal}
                disableGutters
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0',
                    cursor: 'pointer',
                    paddingLeft: '12px',
                    paddingRight: "12px",
                    borderRadius: '12px'
                }}
            >
                <img src={RefsIcon} style={{ borderRadius: "6px", marginLeft: "0px" }} width="24px" height="24px" />
                <div
                    className="d-flex align-items-center"
                    style={{
                        width: '100%',
                        height: '100%',
                        marginLeft: '8px',
                        marginTop: '2px',
                    }}
                >
                    {t('my_refs')}
                </div>
                <ChevronRightIcon sx={{ fill: "var(--tg-theme-subtitle-text-color)" }} />
            </ListItem>

            <Dialog
                fullScreen
                open={openRefsModal}
                onClose={handleCloseRefsModal}
                PaperProps={{
                    style: {
                        backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                        color: 'var(--tg-theme-text-color)',
                    },
                }}
            >
                <DialogTitle>{t('my_refs')}</DialogTitle>
                <DialogContent style={{ width: 'calc(100% - 65px)', height: '100%' }}>
                    <Box>
                        <StyledList>
                            {firstLevel.map((ref, index) => (
                                <React.Fragment key={ref.id}>
                                    <StyledListItem onClick={() => handleClick(ref.id)}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Typography variant="body1">
                                                {ref.ref_name} <strong>{ref.percent}%</strong>
                                            </Typography>
                                        </Box>
                                        {secondLevelMap[ref.ref_uid] && secondLevelMap[ref.ref_uid].length > 0 && (
                                            <RotatingIconButton edge="end" open={openItems[ref.id]}>
                                                <ExpandMoreIcon />
                                            </RotatingIconButton>
                                        )}
                                    </StyledListItem>
                                    {secondLevelMap[ref.ref_uid] && secondLevelMap[ref.ref_uid].length > 0 && (
                                        <Collapse in={openItems[ref.id]} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {secondLevelMap[ref.ref_uid].map(subRef => (
                                                    <SubListItem key={subRef.id}>
                                                        <Typography variant="body2">
                                                            {subRef.ref_name} <strong>{ref.percent >= 50 ? '10%' : '5%'}</strong>
                                                        </Typography>
                                                    </SubListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    )}
                                    {index < firstLevel.length - 1 && (
                                        <Divider sx={{ margin: '8px 12px', backgroundColor: '#e0e0e0' }} />
                                    )}
                                </React.Fragment>
                            ))}
                            {firstLevel.length < 1 && (
                                <Box sx={{ backgroundColor: 'var(--tg-theme-bg-color)', paddingLeft: '8px', paddingRight: "12px", borderRadius: '12px', marginTop: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
                                    <div className={"text-center w-100"} style={{width:"100%", padding: "16px"}}>
                                        {t('refs_not_found')}
                                    </div>
                                </Box>
                            )}
                        </StyledList>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default MyRefs;

import React, { useEffect, useState } from 'react';
import { useTonConnect } from '@tonconnect/ui-react';
import { TonConnectUIProvider, useTonConnectUI } from '@tonconnect/ui-react';
import ConnectButton from "./ConnectButton";
import { useTonWallet } from '@tonconnect/ui-react';
import SendTransaction from "./SendTransaction";
import { useTonAddress } from '@tonconnect/ui-react';
import WebAppzAPI from "../../API/WebAppzAPI";
import MoneyDuck from "./../../Assets/Gifs/money-duck.gif"

const manifestUrl = 'https://a0yqw43aon9fqz7oj618yt3vfkalv0.aydawcux16rbjxf5ufl5c6y.ru/tonconnect-manifest.json';


const App = ({ UserSession }) => {

    const [tonConnectUI, setOptions] = useTonConnectUI();

    const wallet = useTonWallet();

    const userWalletAddress = useTonAddress();

    const handleSaveWalletAddress = async() => {
        const response = await WebAppzAPI.saveWalletAddress(UserSession, userWalletAddress);
        // window.Telegram.WebApp.close();
    }

    useEffect(() => {

        alert(UserSession.WalletAddress);
        alert(userWalletAddress);

        if(userWalletAddress !== null && userWalletAddress !== '' && (UserSession.WalletAddress === null || UserSession.WalletAddress === 'null')) {
            handleSaveWalletAddress();
        }

    }, [userWalletAddress, UserSession]);

    console.log(UserSession);

    if(userWalletAddress) {
        console.log(userWalletAddress);
    }

    return (
        <div style={{padding: 12}}>
            <p className={"comfortaa"} style={{padding: 0, margin: 0, marginBottom: "12px"}}>
                Connect with Telegram Wallet
            </p>
            {
                wallet ? (
                    <div className={"p-2 br-12 telegramBg"}>
                        <span className={"d-flex align-items-center"}>
                            <svg style={{marginRight: "6px"}} xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                 viewBox="0 0 56 56"
                                 fill="none">
                                <path
                                    d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
                                    fill="#0098EA"/>
                                <path
                                    d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z"
                                    fill="white"/>
                            </svg>
                            Wallet address: {userWalletAddress.slice(0, -(userWalletAddress.length - 2))}...{userWalletAddress.slice(userWalletAddress.length - 4)}</span>
                        <button
                            className={"ios-style-button w-100 d-flex align-items-center"}
                            style={{marginTop: "12px"}}
                            onClick={async () => {
                                await tonConnectUI.disconnect();
                                await WebAppzAPI.saveWalletAddress(UserSession, null);
                                window.Telegram.WebApp.close();
                            }}>
                            <svg style={{marginRight: "4px"}} width="22" height="22" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <g id="Interface / Exit">
                                        <path id="Vector"
                                              d="M12 15L15 12M15 12L12 9M15 12H4M4 7.24802V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2839 4.21799 18.9076C4 18.4798 4 17.9201 4 16.8V16.75"
                                              stroke="#fff" strokeWidth="2" strokeLinecap="round"
                                              strokeLinejoin="round"></path>
                                    </g>
                                </g>
                            </svg>
                            Disconnect
                        </button>
                        {/*<SendTransaction />*/}
                    </div>
                ) : (
                    <div className="text-center">
                        <button style={{ marginTop: "8px" }}
                                className={"w-100 ios-style-button d-flex align-items-center"}
                                onClick={() => {
                                    tonConnectUI.openSingleWalletModal('wallet');
                                }}>
                            <svg style={{marginRight: "6px"}} xmlns="http://www.w3.org/2000/svg" width="26" height="26"
                                 viewBox="0 0 56 56"
                                 fill="none">
                                <path
                                    d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
                                    fill="#0098EA"/>
                                <path
                                    d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z"
                                    fill="white"/>
                            </svg>
                            Connect Wallet
                        </button>
                        <div style={{ marginTop: "8px" }} className={"d-flex justify-content-center"}>
                            <img src={MoneyDuck} width={"50%"}/>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

const DisplayWallet = ({user}) => {

    if (user === undefined) {
        return 'Loading...';
    }

    return (
        <TonConnectUIProvider
            config={{
                project: 'WebAppz',
                url: 'https://t.me/webapptestshopblankbot/webapptestshopblank',
            }}
            manifestUrl={manifestUrl}
        >
            <App UserSession={user}/>
        </TonConnectUIProvider>
    )
}

export default DisplayWallet;

import React, { useState } from 'react';
import ShopCard from './../Cards/ShopCard'; // Компонент, который уже используется
import { useTranslation } from 'react-i18next';
import './../../i18n';
import { ListItem,  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box } from '@mui/material'; // Для работы с модальным окном
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ShopIcon from './../../Assets/Svg/shop_icon';
import ShopIcon from './../../Assets/Pngs/my_shops.png';

const Shops = ({ shops, openShop, setOpenShop, userData, openModalShops, setOpenModalShops }) => {
    const [searchTerm, setSearchTerm] = useState('');
    
    const { t } = useTranslation();

    const filteredShops = shops !== undefined && shops !== null ? shops.filter(shop => {
        if (searchTerm.startsWith('@')) {
            const username = searchTerm.slice(1).toLowerCase();
            return shop.bot_tg_username.toLowerCase().includes(username);
        } else if (searchTerm.startsWith('#')) {
            return shop.shopID.toString() === searchTerm.slice(1).toLowerCase();
        } else {
            return true;
        }
    }) : {};

    const handleOpen = () => setOpenModalShops(true);
    const handleClose = () => setOpenModalShops(false);

    return (
        <>
            <ListItem disableGutters sx={{ 
                display: 'flex', 
                alignItems: 'center',
                padding: '8px 0',
                paddingLeft: '12px',
                paddingRight: "12px",
                borderRadius: '12px'
            }} onClick={handleOpen}>
                {/* <ShopIcon sx={{ marginRight: '16px', fontSize: '24px' }} /> */}
                <img src={ShopIcon} style={{ borderRadius: "6px", marginLeft: "0px" }} width="24px" height="24px" />
                <div className="d-flex align-items-center" style={{ width: "100%", height: "100%", marginLeft: "8px", marginTop: "2px" }}>
                    {t('my_shops')}
                </div>
                <ChevronRightIcon sx={{ fill: "var(--tg-theme-subtitle-text-color)" }} />
            </ListItem>

            <Dialog
                open={openModalShops}
                onClose={handleClose}
                fullScreen
                PaperProps={{
                    style: {
                        backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                        color: 'var(--tg-theme-text-color)',
                    }
                }}
            >
                <DialogTitle>{t('my_shops')}</DialogTitle>
                <ShopCard openShop={openShop} setOpenShop={setOpenShop} openModal={openModalShops} setOpenModal={setOpenModalShops} userData={userData} shop={false} />
                <DialogContent>
                    <div className={"row rounded"}>
                        {
                            userData.oo !== undefined && userData.oo === true ? (
                                <div className="col-12 mb-3">
                                    <TextField
                                        fullWidth
                                        label={t('@username or #id')}
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        sx={{color: "var(--tg-theme-text-color)"}}
                                    />
                                </div>
                            ) : null
                        }

                        <Box sx={{ backgroundColor: 'var(--tg-theme-bg-color)', paddingLeft: '8px', paddingRight: "12px", borderRadius: '12px', marginTop: '16px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
                            {filteredShops && filteredShops.length > 0 ? (
                                filteredShops.map(shop => (
                                    <ShopCard openShop={openShop} setOpenShop={setOpenShop} openModal={openModalShops} setOpenModal={setOpenModalShops} userData={userData} key={shop.shopID} shop={shop} />
                                ))
                            ) : (
                                <div className={"text-center w-100"} style={{width:"100%", padding: "16px"}}>
                                    {t('shops_not_found')}
                                </div>
                            )}
                        </Box>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Shops;

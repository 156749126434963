import React, { useEffect, useState, useCallback } from 'react';
import WebAppzAPI from './../API/WebAppzAPI';
import { Button, Typography, CircularProgress, Box, Card, CardContent, CardMedia, ListItem, Divider, Dialog, DialogTitle, DialogContent, SvgIcon } from '@mui/material';
import { styled } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { useTonConnectUI } from '@tonconnect/ui-react';

const paymentMethods = [
    { code: 'XTR', name: 'Telegram Stars', icon: '🌟' },
    { code: 'RUB', name: 'Банковская карта', icon: (
            <SvgIcon style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
            </SvgIcon>
        ) },
    { code: 'WEBAPPZ_CONNECT', name: 'WebAppz Connect', icon: (
            <SvgIcon width="30" height="30" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#0098EA"></path>
                <path d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z" fill="white"></path>
            </SvgIcon>
        ) },
    // { code: 'TON', name: 'Telegram Wallet', icon: (
    //         <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
    //              viewBox="0 0 56 56"
    //              fill="none">
    //             <path
    //                 d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
    //                 fill="#0098EA"/>
    //             <path
    //                 d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z"
    //                 fill="white"/>
    //         </svg>
    //     )
    // },
];

const InvoicePage = ({userData}) => {
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState('XTR');
    const [openModal, setOpenModal] = useState(false);
    const [tgLink, setTgLink] = React.useState(userData.language_code === 'ru' ? 'webappz' : 'webappzorg');
    const [confirmPaymentModalOpen, setConfirmPaymentModalOpen] = useState(false);

    const {t} = useTranslation();

    const checkInvoice = async () => {
        console.log('checked invoice');
        try {
            const response = await WebAppzAPI.checkIsInvoice(userData);
            if (response.data.status === false) {
                window.Telegram.WebApp.MainButton.hide();
                // window.Telegram.WebApp.showAlert(response.data.message);
            } else {
                setInvoice(response.data.invoice);
                if (response.data.invoice.currency !== undefined) {
                    setPaymentMethod(response.data.invoice.currency);
                }
                if(response.data.invoice.status_payment == 0) {
                    window.Telegram.WebApp.MainButton.text = t('pay');
                    window.Telegram.WebApp.MainButton.show();
                } else {
                    window.Telegram.WebApp.MainButton.hide();
                }
            }
        } catch (error) {
            window.Telegram.WebApp.MainButton.hide();
            console.error('Error fetching invoice data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkInvoice();
    }, [userData]);

    const handlePaymentMethodChange = async (newMethod) => {
        setPaymentMethod(newMethod);
        setOpenModal(false);
    };

    React.useEffect(() => {
        const backButton = window.Telegram.WebApp.BackButton;

        if (openModal) {
            backButton.show();
            backButton.onClick(() => {
                setOpenModal(false);
            });
        } else {
            backButton.hide();
        }

        return () => {
            backButton.onClick(null);
            backButton.hide();
        };
    }, [openModal]);

    useEffect(() => {
        const handleMainButtonClick = () => {
            openInvoice(paymentMethod, invoice);
        };

        window.Telegram.WebApp.MainButton.onClick(handleMainButtonClick);

        return () => {
            window.Telegram.WebApp.MainButton.offClick(handleMainButtonClick);
        };
    }, [paymentMethod, invoice]);

    const openInvoice = async (payment, invoice) => {

        if(invoice !== null) {
            if (payment === 'RUB') {

                const createPaymentRequest = await WebAppzAPI.createPay({
                    'userid': userData.userid,
                    'shopidpayment': invoice.shopid,
                    'type_transaction': "payment_shop",
                    'currency': "RUB",
                    'invoice_waz_link': userData.invoice
                }, userData);

                if(createPaymentRequest.data.status !== undefined && createPaymentRequest.data.status === true) {
                    const responsePayTG = await window.Telegram.WebApp.openInvoice(createPaymentRequest.data.invoice_link);
                    window.Telegram.WebApp.onEvent('invoiceClosed', (event) => {
                        if (event.status === 'paid') {
                            checkInvoice();
                            console.log('Payment successful');
                            window.Telegram.WebApp.showAlert(t('payment_successful'));
                        } else if (event.status === 'failed') {
                            window.Telegram.WebApp.HapticFeedback.notificationOccurred('error');
                        } else {
                            window.Telegram.WebApp.HapticFeedback.notificationOccurred('warning');
                        }
                    });
                } else if(createPaymentRequest.data.status !== undefined && createPaymentRequest.data.status === false) {
                    window.Telegram.WebApp.showAlert(createPaymentRequest.data.message);
                } else {
                    window.Telegram.WebApp.showAlert('Server error');
                }

            } else if (payment === 'XTR') {

                const createPaymentRequest = await WebAppzAPI.createPay({
                    'userid': userData.userid,
                    'shopidpayment': invoice.shopid,
                    'type_transaction': "payment_shop",
                    'currency': "XTR",
                    'invoice_waz_link': userData.invoice
                }, userData);

                if (createPaymentRequest.data.invoice_link !== undefined) {
                    const responsePayTG = await window.Telegram.WebApp.openInvoice(createPaymentRequest.data.invoice_link);
                    window.Telegram.WebApp.onEvent('invoiceClosed', (event) => {
                        if (event.status === 'paid') {
                            checkInvoice();
                            console.log('Payment successful');
                            window.Telegram.WebApp.showAlert(t('payment_successful'));
                        } else if (event.status === 'failed') {
                            window.Telegram.WebApp.HapticFeedback.notificationOccurred('error');
                        } else {
                            window.Telegram.WebApp.HapticFeedback.notificationOccurred('warning');
                        }
                    });
                } else {
                    window.Telegram.WebApp.showAlert(t("start_payment_failed"));
                }
            } else if(payment === 'WEBAPPZ_CONNECT') {
                setConfirmPaymentModalOpen(true);
            }
        }
    };

    const handleConfirmPayment = async () => {
        if(invoice !== null) {

            setLoading(true);
            window.Telegram.WebApp.MainButton.hide();

            const createPaymentRequest = await WebAppzAPI.createPay({
                'userid': userData.userid,
                'shopidpayment': invoice.shopid,
                'type_transaction': "payment_shop",
                'currency': "WEBAPPZ_CONNECT",
                'invoice_waz_link': userData.invoice
            }, userData);

            if(createPaymentRequest.data.status !== undefined && createPaymentRequest.data.status === true) {
                checkInvoice();
                userData.balance = createPaymentRequest.data.balance;
            } else {
                window.Telegram.WebApp.showAlert(createPaymentRequest.data.message);
                userData.balance = createPaymentRequest.data.balance;
            }

            setLoading(false);
            window.Telegram.WebApp.MainButton.show();
            setConfirmPaymentModalOpen(false);
        }
    };

    const handleCancelPayment = () => {
        setConfirmPaymentModalOpen(false);
    };

    const [tonConnectUI, setOptions] = useTonConnectUI();

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    const StyledCard = styled(Card)({
        maxWidth: 400,
        margin: '20px auto',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        backgroundColor: '#1c1c1e',
        color: '#fff',
    });

    const StyledButton = styled(Button)({
        backgroundColor: '#007bff',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
        width: '100%',
        padding: '10px',
        fontSize: '16px',
    });

    const LabelText = styled(Typography)({
        color: '#888',
        fontSize: '14px',
    });

    // const transaction: SendTransactionRequest = {
    //     validUntil: Date.now() + 5 * 60 * 1000,
    //     messages: [
    //         {
    //             address:
    //                 "UQCITGpH9TMXprqiiU-q56eSB_t6Td1a2N77-ACfIG3g9HiY",
    //             amount: invoice.amountTON * 1000000000,
    //         },
    //     ],
    // };

    return (
        <Box sx={{padding: '20px', backgroundColor: 'var(--tg-theme-secondary-bg-color)'}}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                {
                    invoice ? (
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <img src={invoice.shop_image}
                                 style={{borderRadius: "50%", width: "60px", marginRight: "5px"}} alt=""/>
                            {t('payment_shop')} "{invoice.shop_name}"
                        </Box>
                    ) : (
                        'Транзакция не найдена'
                    )
                }
            </Box>
            {
                invoice && (
                    <Box sx={{width: "100%"}}>
                        <h1 className={"comfortaa"} style={{marginBottom: 0}}>
                            {
                                userData.language_code === 'ru' ? (
                                    <>
                                        {invoice.amountRUB}&nbsp;
                                        <b style={{color: "var(--tg-theme-hint-color)"}}>
                                            RUB
                                        </b>
                                    </>
                                ) : (
                                    <>
                                        {invoice.amountUSD}&nbsp;
                                        <b style={{color: "var(--tg-theme-hint-color)"}}>
                                            USD
                                        </b>
                                    </>
                                )
                            }
                        </h1>
                        <b className={"comfortaa"} style={{display: "flex"}}>
                            {invoice.amountTON}&nbsp;
                            <b style={{color: "var(--tg-theme-hint-color)", display: "flex"}}>
                                TON&nbsp;
                                <CardMedia
                                    component="svg"
                                    viewBox="0 0 56 56"
                                    sx={{width: 20, height: 20, marginRight: 1}}
                                >
                                    <path
                                        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
                                        fill="#0098EA"></path>
                                    <path
                                        d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z"
                                        fill="white"></path>
                                </CardMedia>
                            </b>
                        </b>
                    </Box>
                )
            }
            {/*<button onClick={async() => {*/}
            {/*    const responseWallet =  tonConnectUI.sendTransaction(transaction);*/}
            {/*    console.log(responseWallet)*/}
            {/*}}>*/}
            {/*    Send transaction*/}
            {/*</button>*/}
            {!invoice ? (
                <Typography variant="body1" align="center" color="white">Нет активных счетов.</Typography>
            ) : (
                <Box sx={{backgroundColor: 'var(--tg-theme-bg-color)', borderRadius: '12px', marginTop: '16px'}}>
                    <ListItem
                        disableGutters
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 0',
                            paddingLeft: '12px',
                            paddingRight: "12px",
                            borderRadius: '12px',
                            cursor: 'pointer',
                            justifyContent: "space-between"
                        }}
                        onClick={() => {
                            if (invoice.status_payment === 0) {
                                setOpenModal(true);
                            }
                        }}
                    >
                        <div className="d-flex align-items-center" style={{width: "max-content", height: "100%"}}>
                            {t('method_payment')}
                        </div>
                        <div className="d-flex align-items-center" style={{
                            marginLeft: '8px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {(() => {
                                const selectedMethod = paymentMethods.find(method => method.code === paymentMethod);
                                return (
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {selectedMethod?.icon}
                                        <Typography variant="body1"
                                                    sx={{marginLeft: '8px', color: 'var(--tg-theme-text-color)'}}>
                                            {selectedMethod?.name}
                                        </Typography>
                                    </div>
                                );
                            })()}
                        </div>
                    </ListItem>

                    {
                        paymentMethod === 'WEBAPPZ_CONNECT' && (
                            <>
                                <Divider sx={{marginLeft: '12px'}}/>

                                <ListItem disableGutters sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px 0',
                                    paddingLeft: '12px',
                                    paddingRight: "12px",
                                    borderRadius: '12px',
                                    justifyContent: "space-between"
                                }}>
                                    <div className="d-flex align-items-center"
                                         style={{width: "max-content", height: "100%"}}>
                                        {t('balance')}
                                    </div>
                                    <div className="d-flex align-items-center comfortaa" style={{
                                        marginLeft: '8px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {userData.balance}
                                        &nbsp;
                                        <b style={{color: "var(--tg-theme-hint-color)"}}>TON</b>
                                        {/*<CardMedia*/}
                                        {/*    component="svg"*/}
                                        {/*    viewBox="0 0 56 56"*/}
                                        {/*    sx={{width: 20, height: 20, marginRight: 1}}*/}
                                        {/*>*/}
                                        {/*    <path*/}
                                        {/*        d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"*/}
                                        {/*        fill="#0098EA"></path>*/}
                                        {/*    <path*/}
                                        {/*        d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z"*/}
                                        {/*        fill="white"></path>*/}
                                        {/*</CardMedia>*/}
                                    </div>
                                </ListItem>
                            </>
                        )
                    }

                    <Divider sx={{marginLeft: '12px'}}/>

                    <ListItem disableGutters sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 0',
                        paddingLeft: '12px',
                        paddingRight: "12px",
                        borderRadius: '12px',
                        justifyContent: "space-between"
                    }}>
                        <div className="d-flex align-items-center" style={{width: "max-content", height: "100%"}}>
                            {t('status_payment')}
                        </div>
                        <div className="d-flex align-items-center" style={{
                            marginLeft: '8px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: "var(--tg-theme-hint-color)"
                        }}>
                            {
                                invoice.status_payment === 0 ? t('awaiting_payment') :
                                    invoice.status_payment === 1 ? t('paid') :
                                        t('payment_cancelled')
                            }
                        </div>
                    </ListItem>
                </Box>
            )}

            <Dialog
                fullScreen
                open={openModal}
                onClose={() => setOpenModal(false)}
                PaperProps={{
                    style: {
                        backgroundColor: 'var(--tg-theme-bg-color)',
                        color: 'var(--tg-theme-text-color)',
                    }
                }}
            >
                <DialogTitle>{t('choose_a_payment_method')}</DialogTitle>
                <DialogContent>
                    {paymentMethods.map((method, index) => (
                        <React.Fragment key={method.code}>
                            {
                                method.code === 'RUB' && userData.language_code === 'ru' && invoice !== null && invoice.cc !== false ? (
                                    <>
                                        <ListItem
                                            disableGutters
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '8px 0',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handlePaymentMethodChange(method.code)}
                                        >
                                            <div className="d-flex align-items-center"
                                                 style={{marginRight: '8px', width: "30px"}}>
                                                {method.icon}
                                            </div>
                                            <div className="d-flex align-items-center" style={{
                                                width: '100%',
                                                height: '100%',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {method.name}
                                            </div>
                                            {paymentMethod === method.code && (
                                                <CheckIcon sx={{color: '#007AFF'}}/>
                                            )}
                                        </ListItem>
                                        {index < paymentMethods.length - 1 && (
                                            <Divider sx={{marginLeft: '37px'}}/>
                                        )}
                                    </>
                                ) : method.code !== 'RUB' && (
                                    <>
                                        <ListItem
                                            disableGutters
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '8px 0',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handlePaymentMethodChange(method.code)}
                                        >
                                            <div className="d-flex align-items-center"
                                                 style={{marginRight: '8px', width: "30px"}}>
                                                {method.icon}
                                            </div>
                                            <div className="d-flex align-items-center" style={{
                                                width: '100%',
                                                height: '100%',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {method.name}
                                            </div>
                                            {paymentMethod === method.code && (
                                                <CheckIcon sx={{color: '#007AFF'}}/>
                                            )}
                                        </ListItem>
                                        {index < paymentMethods.length - 1 && (
                                            <Divider sx={{marginLeft: '37px'}}/>
                                        )}
                                    </>
                                )
                            }
                        </React.Fragment>
                    ))}
                </DialogContent>
            </Dialog>
            <Dialog
                open={confirmPaymentModalOpen}
                onClose={handleCancelPayment}
                PaperProps={{
                    style: {
                        backgroundColor: 'var(--tg-theme-bg-color)',
                        color: 'var(--tg-theme-text-color)',
                    }
                }}
            >
                <DialogTitle>{t('confirm_purchase')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {t('do_you_want_to_buy')} "{invoice.shop_name}"?
                    </Typography>
                    <Box display="flex" justifyContent="space-between" mt={3}>
                        <Button variant="contained" sx={{width: "100%"}} className={"ios-style-button"} color="primary"
                                onClick={handleConfirmPayment}>
                            {t('confirm_and_pay')}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <div style={{width: "100%", marginTop: "15px", color: "var(--tg-theme-subtitle-text-color)"}}
                 className="d-flex justify-content-center">
                Powered by&nbsp;<a style={{color: "var(--tg-theme-subtitle-text-color)"}}
                                   href={`https://t.me/${tgLink}`}>@{tgLink}</a>
            </div>
            <div style={{width: "100%", color: "var(--tg-theme-subtitle-text-color)", marginTop: "5px"}}
                 className="d-flex justify-content-center">
                &copy; 2023-{new Date().getFullYear()}
            </div>
        </Box>
    );
};

export default InvoicePage;